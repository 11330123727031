<!--suppress XmlHighlighting, XmlHighlighting -->
<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content' style='padding-top: 20px'>
        <!--        <el-divider content-position="left">编辑-红人平台</el-divider>-->
        <el-row :gutter='20'>
          <el-col :xl='12' :lg='12' :md='24' :xs='24' class='content-left'>
            <el-form ref='artistForm' :model='formData' :rules='rules' :inline='false'
                     label-position='right'
                     label-width='100px'>
              <el-form-item label='红人昵称' prop='nickname'>
                <el-input v-model='formData.nickname' readonly/>
              </el-form-item>
              <!--              <el-form-item label="头像">-->
              <!--                <ArtistAvatar :user="formData" @uploaded="getCover" />-->
              <!--              </el-form-item>-->
              <el-form-item label='平台账号' prop='name'>
                <div class='div-block' style='display: block;height: auto;padding-bottom: 10px'>
                  <el-row v-for='item in platforms' :key='item.id' :gutter='20'>
                    <el-col :span='8'>
                      <div style='display: flex'>
                        <el-image
                            class='platform-img'
                            :src='item.logo_url'
                            fit='contain'>
                        </el-image>
                        <span class='platform-title'>{{ item.name }}：</span>
                      </div>
                    </el-col>
                    <el-col :span='16'>
                      <div style='border-bottom: 1px dashed #DCDFE6;width: 300px;text-align: center'>
                        <el-radio-group v-model='formData.platforms[item.code]'>
                          <el-radio label='N' value='N'>无</el-radio>
                          <el-radio label='Y' value='Y'>有</el-radio>
                        </el-radio-group>
                        <el-link :type="`${item.code===currentPlatformCode?'primary':''}`"
                                 @click='showKolPlatformInfo(item.code)'
                                 style='margin-left: 60px'>（平台信息）更多<i
                            class='el-icon-d-arrow-right' style='font-size: 10px'></i>
                        </el-link>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </el-form-item>
              <el-form-item label='肤质'>
                <el-input v-model='formData.skin_type' placeholder='请输入KOL肤质' maxlength='50'
                          :show-word-limit='true' :clearable='true'/>
              </el-form-item>
              <el-form-item label='标签'>
                <el-input v-model='formData.kol_label' placeholder='请输入KOL标签,多个标签用顿号（”、“）隔开'
                          maxlength='100'
                          :show-word-limit='true' :clearable='true'/>
              </el-form-item>
              <el-form-item label='直播类型'>
                <el-radio-group v-model='formData.live_type'>
                  <el-radio :label='liveTypeOption.value' :value='liveTypeOption.value'
                            v-for='(liveTypeOption) in liveTypeOptions' :key='liveTypeOption.value'>
                    {{ liveTypeOption.name }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label='备注'>
                <el-input v-model='formData.remark' type='textarea' :rows='3' placeholder='请输入KOL备注'
                          readonly/>
              </el-form-item>
              <el-form-item label='签约信息'>
                <div class='div-block' style='display: block;height: auto;padding-bottom: 10px'>
                  <div style='display: flex'>
                    <span class='sign-info'>签约状态：</span> <span
                      class='sign-item'>{{ formData.sign_status == 'Y' ? '已签约' : '已解约' }}</span>
                  </div>
                  <div style='display: flex'>
                    <span class='sign-info'>签约时间：</span> <span
                      class='sign-item'>{{ $utils.parseTime(formData.sign_start, '{y}/{m}/{d}') }}</span>
                  </div>
                  <div style='display: flex'>
                    <span class='sign-info'>合约到期：</span> <span
                      class='sign-item'>{{ $utils.parseTime(formData.sign_end, '{y}/{m}/{d}') }}</span>
                  </div>
                  <div style='display: flex' v-if="formData.sign_status==='N'">
                    <span class='sign-info'>解约时间：</span> <span
                      class='sign-item'>{{ $utils.parseTime(formData.fired_at, '{y}/{m}/{d}') }}</span>
                  </div>
                </div>
              </el-form-item>
              <el-form-item style='text-align: center'>
                <el-button type='primary' @click='saveConfirm' size='large'>
                  提交
                </el-button>
                <el-button @click='returnList' size='large'>返回</el-button>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :xl='12' :lg='12' :md='24' :xs='24'>
            <KolPlatformInfo :platform-code='currentPlatformCode' :artist-id='artistId'/>
          </el-col>
        </el-row>
        <div class="delBox"  v-if="userPermissions.indexOf('artist_delete') !== -1">
          <el-button class="delButton" size="big" @click="handleDelete">删除红人</el-button>

        </div>
        <!--        日志-->
        <div style='padding: 10px'>
          <ArtistLog :artist-id='artistId'/>
        </div>
      </div>
      <el-dialog
          :visible.sync="deleteVisible"
          width="30%"
      >
        <template slot="title">删除红人<span style="color: #d1242f">  {{ formData.nickname }}</span></template>
        <div class="delInput">
          <i class="el-icon-delete" style="color: #d1242f;font-size: 30px;text-align: center;display: block"></i>
          <br/>
          <b style="text-align: center;color: #1f2328">
            确认删除，在输入框输入 <span style="color: #d1242f"> {{ formData.nickname }}</span>
          </b>
          <el-input oninput="value = value.replace(/\s/g, '')" v-model="delNickname"
                    style="display: block;width: 80%;margin: 20px auto;" width="80%"></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
    <el-button @click="deleteVisible = false">取 消</el-button>
    <el-button type="primary" @click="sureDelete">确 定</el-button>
  </span>
      </el-dialog>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import { mapGetters } from 'vuex'
import KolPlatformInfo from '@/pages/artist/KolPlatformInfo'
import ArtistLog from '@/pages/artist/ArtistLog'

export default {
  name: 'ArtistCreateEdit',
  components: {
    ArtistLog,
    KolPlatformInfo,
    PageHeaderLayout
  },
  data() {
    return {
      artistId: null, // 红人id，null表示创建，非null为编辑
      platforms: [],
      squareUrl: 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png',
      formData: {
        nickname: '',
        group_id: null,
        group_time: null,
        sign_status: 'Y',
        platforms: { 'douyin': 'Y', 'kuaishou': 'Y', 'xiaohongshu': 'Y', 'weibo': 'Y', 'bilibili': 'Y' },
        sign_start: null,
        sign_end: null,
        fired_at: null,
        remark: '',
        avatar: 'https://eziyan.oss-cn-hangzhou.aliyuncs.com/Logo/logo_108_108.png',
        skin_type: '',
        live_type: null,
        kol_label: ''
      },
      liveTypeOptions: [
        { value: 1, name: '达播' },
        { value: 2, name: '日播' }
      ],
      rules: {
        nickname: [
          { required: true, message: '输入红人昵称', trigger: 'blur' }
        ],
        group_id: [{ required: true, message: '分组必选', trigger: 'blur' }],
        sign_status: [{ required: true, message: '签约状态必选', trigger: 'blur' }]
      },
      currentPlatformCode: '',//当前选中的平台
      deleteVisible: false,
      delNickname: ''
    }
  },
  computed: {
    ...mapGetters(['userPermissions'])

  },
  created() {
    this.initForm()
  },
  methods: {
    defaultPlatforms() {
      // 平台账号：默认有账号
      let platforms = {}
      this.platforms.forEach((platform) => {
        platforms[platform.code] = 'N'
      })
      this.formData.platforms = platforms
    },
    defaultPlatformCode() {
      //默认显示第一个有账号的平台
      let platformsMap = this.formData.platforms
      this.platforms.some((platform) => {
        let code = platform.code
        let status = platformsMap[code]
        if (status === 'Y') {
          this.currentPlatformCode = code
          return true
        }
      })
    },
    async initForm() {
      //分组列表
      await this.getPlatforms()
      // 签约状态：默认签约
      this.formData.sign_status = 'Y'
      await this.defaultPlatforms()
      // 获取到ID：编辑
      if (this.$route.params.artist_id) {
        this.artistId = this.$route.params.artist_id
      }
      if (this.artistId) {
        await this.getArtistDetail()
      }
      this.defaultPlatformCode()
    },
    async getArtistDetail() {
      this.deleteVisible=false
      let { info } = await this.$api.getArtistInfo(this.artistId)
      this.formData = info
      // this.formData.avatar = 'https://eziyan.oss-cn-hangzhou.aliyuncs.com/Logo/logo_108_108.png'
      this.formData.avatar = 'http://game-admin.pblab.com/api/pub_upload/2022-02-25/ci4vd60uaoi0osus7r.jpg'
      if (!this.formData.platforms) {
        this.defaultPlatforms()
      }
    },
    // 返回列表
    returnList() {
      this.$router.push('/artist')
    },
    // 确认保存按钮
    async saveConfirm() {
      // 调用组件的数据验证方法
      this.$refs['artistForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
      return true
    },
    // 相关信息的保存处理
    async formSubmit() {
      //只传允许修改的字段
      let postData = {
        id: this.formData.id,
        platforms: this.formData.platforms,
        kol_label: this.formData.kol_label,
        skin_type: this.formData.skin_type,
        live_type: this.formData.live_type
      }
      let id = await this.$api.saveArtist(postData)
      if (id) {
        this.$notify.success('保存成功！')
        await this.initForm()
      }
      if (!this.artistId && id) {
        // this.$router.push(this.$route.matched[1].path+'/'+id+'/edit')
        this.$router.push(this.$route.matched[1].path)
      }
    },
    async getPlatforms() {
      // 获取最新平台信息
      let { list } = await this.$api.getPlatformAll({ status: 1 })
      this.platforms = list
      // 缓存平台信息
      localStorage.setItem('platforms', JSON.stringify(list))

    },
    showKolPlatformInfo(val) {
      this.currentPlatformCode = val
    },
    handleDelete() {
      this.deleteVisible = true
    },
    // 相应删除按钮
    async deleteButton(id) {
      let info = await this.$api.deleteArtist(id)
      if (info === 'ok') {
        this.$message.success('删除成功')
        this.deleteVisible = false
        this.$router.push('/artist')
      } else {
        this.$message.error(info)
      }
    },
    sureDelete() {
      if (this.delNickname === this.formData.nickname) {
        this.deleteButton(this.formData.id)
      } else {
        this.$message.error(`请输入正确内容： ${this.formData.nickname}`)
      }

    }
  }
}
</script>

<style>
.div-block {
  -webkit-appearance: none;
  background-color: #FFFFFF;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  outline: none;
  padding: 15px 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

.platform-img {
  width: 22px;
  height: 22px;
  flex: 2;
  text-align: right
}

.platform-title {
  font-weight: bold;
  margin-left: 10px;
  line-height: 22px;
  flex: 4;
  text-align: left;
}

.content-left {
  border-right: 1px solid #e8e8e8
}

.sign-info {
  text-align: right;
}

.sign-item {
  border-bottom: 1px dashed #DCDFE6;
  width: 200px;
  text-align: center;
}

.delBox {
  /*border: 1px solid red;*/
}

.delButton {
  display: block;
  text-align: center;
  margin: 0 auto;
  background-color: #F6F8FB;
  border: 1px solid #d0d7de;
  color: #d1242f;
}

.delInput {
  text-align: center;
}

.delButton:hover {
  background-color: #d1242f;
  color: white;
}
</style>

