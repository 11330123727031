<template>
  <div v-loading="loadingStatus" style="width: 96%;margin: auto">
    <el-link class="title" @click="getInfo">平台信息详情</el-link>
    <el-form ref="refForm" :model="formData" :rules="rules" :inline="false" label-width="100px" class="platform_item">
      <el-row :gutter="15" >
        <el-col :span="24" style=" border-right: 1px dashed #e8e8e8">
          <el-form-item label="KOL头像" prop="cover">
            <el-row>
              <el-col :span="24">
                <ArtistAvatar :img-url="formData.cover_pc_url" @uploaded="getCover"/>
              </el-col>
<!--              <el-col :span="12">-->
<!--                <ApeUploader :limit="1"-->
<!--                             @handleUploadSuccess="handleUploadSuccess"-->
<!--                             @handleUploadRemove="handleUploadRemove"-->
<!--                             :upload-file-list="uploadFileList" :allow-type-list="allowTypeList"></ApeUploader>-->
<!--              </el-col>-->
<!--              <el-col :span="12" style="border-left: dashed 1px #ff3176;text-align: center;padding-left: 10px">-->
<!--                <div style="height: 150px;display: flex;flex-direction: row">-->
<!--                  <div style="font-weight: 800">裁剪上传</div>-->
<!--                  <ArtistAvatar :img-url="formData.cover_pc_url" @uploaded="getCover"/>-->
<!--                </div>-->

<!--              </el-col>-->
            </el-row>

          </el-form-item>
        </el-col>
        <el-col :span="24">
          <!--          <el-form-item label="红人ID" label-width="100px" prop="artist_id">-->
          <!--            <el-input v-model="formData.artist_id" show-word-limit maxlength="60" readonly/>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item label="平台代号" label-width="100px" prop="code">-->
          <!--            <el-input v-model="formData.code" show-word-limit maxlength="60" readonly/>-->
          <!--          </el-form-item>-->
          <el-form-item label="平台ID" label-width="100px" prop="account_id">
            <el-input v-model="formData.account_id" show-word-limit maxlength="100"/>
          </el-form-item>
          <el-form-item label="经纪人" label-width="100px">
            <el-input v-model="formData.agent_name" show-word-limit maxlength="100"/>
          </el-form-item>
          <el-form-item label="城市" label-width="100px">
            <el-input v-model="formData.city" show-word-limit maxlength="120"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="主页链接" label-width="100px">
        <el-input v-model="formData.home_page_link" placeholder="主页链接" maxlength="200">
          <el-button slot="append" icon="el-icon-link" title="点击跳转到主页"
                     @click="openUrl(formData.home_page_link)"></el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="简介" label-width="100px">
        <el-input v-model="formData.intro" type="textarea" :rows="10" placeholder="请输入KOL简介" show-word-limit
                  maxlength="500"/>
      </el-form-item>
      <div class="right-button" v-if="formData.artist_id&& formData.code">
        <el-button size="medium" type="primary" :disabled="loadingStatus" @click="saveConfirm">保存</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import ApeUploader from '@/components/ApeUploader'
import ArtistAvatar from "@/pages/artist/ArtistAvatar";

export default {
  name: "KolPlatformInfo",
  components: {
    ArtistAvatar,
    ApeUploader
  },
  props: {
    platformCode: {
      type: String,
      default() {
        return ''
      }
    },
    artistId: {
      type: String,
      default() {
        return ''
      }
    }
  },
  watch: {
    platformCode(val) {
      this.formData.code = val
      this.formData.artist_id = this.artistId
      this.getInfo()
    }
  },
  data() {
    return {
      // 已上传图片列表
      uploadFileList: [],
      loadingStatus: false,
      allowTypeList: ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/bmp'],
      formData: {
        artist_id: '',
        code: '',
        account_id: '',
        home_page_link: '',
        agent_name: '',
        city: '',
        intro: '',
        cover_pc_url: null
      },
      rules: {
        code: [{ required: true, message: '选择平台', trigger: 'blur' }],
        artist_id: [{ required: true, message: '选择红人', trigger: 'blur' }],
      }
    }
  },
  methods: {
    getCover(val) {
      this.formData.cover = val.id || null
      this.formData.cover_pc_url = val.full_path || null
      this.uploadFileList = [{ id: val.id, url: val.full_path }]
      this.$forceUpdate()
    },
    // 图片上传成功回调
    handleUploadSuccess(file, fileList) {
      // console.log('file',file)
      this.formData.cover = file.id
      this.formData.cover_pc_url = file.full_path || null
      this.uploadFileList = fileList
    },
    // 图片删除回调
    handleUploadRemove(file, fileList) {
      this.formData.cover = 0
      this.formData.cover_pc_url = null
      this.uploadFileList = fileList
    },
    // 附件上传成功回调
    handleAttachmentUploadSuccess(file, fileList) {
      this.formData.attachment = file.id
      this.attachmentUploadFileList = fileList
    },
    // 附件删除回调
    handleAttachmentUploadRemove(file, fileList) {
      this.formData.attachment = 0
      this.attachmentUploadFileList = fileList
    },
    // 图片上传自定义验证
    apeUploaderVerify(rule, value, callback) {
      if (rule.required && !this.formData.cover) {
        callback(new Error(rule.message))
      }
      callback()
    },
    //保存
    saveConfirm() {
      // 调用组件的数据验证方法
      this.$refs['refForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
      return true
    },
    // 文章相关信息的保存处理
    async formSubmit() {
      this.loadingStatus = true
      let id = await this.$api.saveArtistPlatformInfo(this.formData)
      if (id) {
        this.$notify.success('保存成功！')
      }
      this.$nextTick(() => {
        this.loadingStatus = false
      })
    },
    async getInfo() {
      this.loadingStatus = true
      if (this.formData.code) {
        let { info } = await this.$api.getArtistPlatformInfo({
          code: this.formData.code,
          artist_id: this.formData.artist_id
        })

        this.formData = info
        if (info.cover) {
          // this.uploadFileList = [{ id: info.cover_info.id, name: info.cover_info.title, url: info.cover_info.full_path }]
          this.uploadFileList = [{ id: info.cover, url: info.cover_pc_url }]
        } else {
          this.uploadFileList = []
        }

        this.loadingStatus = false
      }
    },
    openUrl(href) {
      window.open(href, '_blank')
    },
  },
  created() {
    this.formData.code = this.platformCode;
    this.formData.artist_id = this.artistId;
    this.getInfo()
  }
}
</script>

<style>
.right-button {
  border-top: 1px solid #e8e8e8;
  padding: 12px;

}

.title {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 10px;
}

.platform_item {
  padding: 10px 10px;
}

</style>
